var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h6',{staticClass:"ml-4",domProps:{"textContent":_vm._s(_vm.$t('evaluation.stats.progression.subtitle1'))}}),_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th'),_vm._l((_vm.getProgressionDataTable.columnsHeader),function(item,index){return [(_vm.getProgressionDataTable.columnToShow[index])?_c('th',{key:index,staticClass:"text-center",domProps:{"innerHTML":_vm._s(item)}}):_vm._e()]})],2)]),_c('tbody',[_vm._l((_vm.classifiers.dimensions),function(dimension,index){return [_c('tr',{key:dimension.id},[_c('td',{staticClass:"primary",attrs:{"colspan":"100%"},domProps:{"textContent":_vm._s(dimension.name)}})]),_vm._l((_vm.classifiers.attributes.filter(
                            att => att.dimensions_id === dimension.id
                        )),function(attribute,index){return _c('tr',{key:`attribute${attribute.id}`},[_c('td',[_c('ul',[_c('li',{domProps:{"textContent":_vm._s(attribute.name)}})])]),_vm._l((_vm.getProgressionDataTable.dataCurrentPeriod[attribute.id - 1]),function(item,itemIndex){return [(_vm.getProgressionDataTable.columnToShow[itemIndex])?_c('td',{staticClass:"text-center",class:_vm.getColorText(item, _vm.getDataPreviousValue(attribute.id - 1, itemIndex))},[_c('span',{domProps:{"textContent":_vm._s(_vm.getDataPreviousValue(attribute.id - 1, itemIndex) || '-')}}),_vm._v(" / "),_c('span',{domProps:{"textContent":_vm._s(item || 0)}})]):_vm._e()]})],2)})]}),_c('tr',[_c('td'),_vm._l((_vm.getProgressionDataTable.dataCurrentPeriod[
                            _vm.getProgressionDataTable.ROWS_COUNT - 1
                        ]),function(item,itemIndex){return [(_vm.getProgressionDataTable.columnToShow[itemIndex])?_c('td',{staticClass:"text-center",class:_vm.getColorText(
                                    item,
                                    _vm.getDataPreviousValue(_vm.getProgressionDataTable.ROWS_COUNT - 1, itemIndex)
                                )},[_c('span',{domProps:{"textContent":_vm._s(
                                    _vm.getDataPreviousValue(_vm.getProgressionDataTable.ROWS_COUNT - 1, itemIndex) || '-'
                                )}}),_vm._v(" / "),_c('span',{domProps:{"textContent":_vm._s(item || 0)}})]):_vm._e()]})],2)],2)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }