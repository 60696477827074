<template>
    <div>
        <ProgressionTable
            :classifiers="classifiers"
            :userData="userData"
            :getProgressionDataTable="getProgressionDataTable"
        />

        <ProgressionChart
            class="mt-8"
            :classifiers="classifiers"
            :isDataLoaded="isDataLoaded"
            :getColumnSortedDataTableInfo="getColumnSortedDataTableInfo"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import evaluationResultMixin from '@/mixins/evaluationResultMixin';

import ProgressionTable from '@/components/games/evaluations/results/ProgressionTable';
import ProgressionChart from '@/components/games/evaluations/results/ProgressionChart';

export default {
    mixins: [evaluationResultMixin],
    components: { ProgressionTable, ProgressionChart },
    props: {
        classifiers: {
            type: Object,
            required: true
        },
        userData: {
            type: Object,
            required: true
        },
        isDataLoaded: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        ...mapGetters('statistics', [
            'getUserEvaluations',
            'getAttributeEvaluationTypeDataStats',
            'getAttributeEvaluationTypeDataStatsFromPreviousPeriod'
        ]),

        getColumnSortedDataTableInfo() {
            const currentPeriod = this.getColumnSortedDataTableInfoByPeriod();
            const previousPeriod = this.getColumnSortedDataTableInfoByPeriod(false);

            return { currentPeriod, previousPeriod };
        },

        getProgressionDataTable() {
            const ROWS_COUNT = 7; // attributes and totals
            const COLUMNS_COUNT = 4;

            const { currentPeriod, previousPeriod } = this.getColumnSortedDataTableInfo;

            const { data: dataCurrentPeriod, columnToShow: columnToShowCurrentPeriod } =
                this.getProgressionDataTableByPeriod(currentPeriod, ROWS_COUNT, COLUMNS_COUNT);

            const { data: dataPreviousPeriod, columnToShow: columnToShowPreviousPeriod } =
                this.getProgressionDataTableByPeriod(previousPeriod, ROWS_COUNT, COLUMNS_COUNT);

            const columnToShow = Array.from(
                { length: COLUMNS_COUNT },
                (v, k) => columnToShowCurrentPeriod[k] || columnToShowPreviousPeriod[k]
            );

            // [selfAppraisal, responsible (previous collaborators), pairs, collaborators(previous responsible) ]
            const columnsHeader = [
                this.$t('evaluation.stats.personsType.selfAppraisal'),
                this.$t('evaluation.stats.personsType.responsible'),
                this.$t('evaluation.stats.personsType.pairs'),
                this.$t('evaluation.stats.personsType.collaborators')
            ];

            return { dataCurrentPeriod, dataPreviousPeriod, columnsHeader, columnToShow, ROWS_COUNT, COLUMNS_COUNT };
        }
    },
    methods: {
        getColumnSortedDataTableInfoByPeriod(isCurrentPeriod = true) {
            /* EvaluationTypes position
                    selfAppraisal: 0,
                    responsible: 1,
                    collaborators: 2,
                    pairs: 3
            */

            // NEW POSITIONS: [selfAppraisal, responsible (previous collaborators), pairs, collaborators(previous responsible) ]
            const EVALUATION_TYPES_POSITION_COLUMNS = [0, 2, 3, 1];

            const { matrixAvg: attributeAvgMatrix, evaluationTypesExistIdMap } = isCurrentPeriod
                ? this.getAttributeEvaluationTypeDataStats
                : this.getAttributeEvaluationTypeDataStatsFromPreviousPeriod;

            const attributeAvgMatrixWithCustomColumns = Array.from(
                { length: attributeAvgMatrix.length },
                (v, k) => new Array(attributeAvgMatrix[k].length)
            );

            for (let indexRow = 0; indexRow < attributeAvgMatrix.length; indexRow++) {
                for (let indexColumn = 0; indexColumn < attributeAvgMatrix?.[0]?.length ?? 0; indexColumn++) {
                    const newColumnIndex = EVALUATION_TYPES_POSITION_COLUMNS[indexColumn];

                    attributeAvgMatrixWithCustomColumns[indexRow][indexColumn] =
                        attributeAvgMatrix[indexRow][newColumnIndex];
                }
            }

            const columnsIndexToShow = {};
            for (let index = 0; index < EVALUATION_TYPES_POSITION_COLUMNS.length; index++) {
                const previousColumnIndex = EVALUATION_TYPES_POSITION_COLUMNS[index];
                columnsIndexToShow[index] = evaluationTypesExistIdMap[previousColumnIndex + 1];
            }

            return { attributeAvgMatrixWithCustomColumns, columnsIndexToShow };
        },

        getProgressionDataTableByPeriod(dataColumnSortedFromPeriod, ROWS_COUNT, COLUMNS_COUNT) {
            const { attributeAvgMatrixWithCustomColumns, columnsIndexToShow: columnsBasicIndexToShow } =
                dataColumnSortedFromPeriod;

            const data = Array.from({ length: ROWS_COUNT - 1 }, (v, k) => attributeAvgMatrixWithCustomColumns[k]);

            // Average result
            data.push(new Array(COLUMNS_COUNT)); // last row for column avg
            for (let columnIndex = 0; columnIndex < COLUMNS_COUNT; columnIndex++) {
                let sum = 0;
                let countValidItems = 0;

                for (let rowIndex = 0; rowIndex < ROWS_COUNT - 1; rowIndex++) {
                    const currentValue = data[rowIndex][columnIndex];

                    if (currentValue) {
                        sum += currentValue;
                        countValidItems++;
                    }
                }

                data[ROWS_COUNT - 1][columnIndex] = countValidItems
                    ? this.numberHelper.round(sum / countValidItems, 2)
                    : 0;
            }

            const columnToShow = Array.from({ length: COLUMNS_COUNT }, () => true);
            for (let index = 1; index < COLUMNS_COUNT; index++) {
                if (!columnsBasicIndexToShow[index]) {
                    columnToShow[index] = false;
                }
            }

            return { data, columnToShow };
        }
    }
};
</script>

<style lang="scss" scoped></style>
