<template>
    <v-container class="mt-0 pt-0 evaluating-results">
        <div
            v-if="postInfo.image"
            class="d-flex justify-center mb-4"
        >
            <img
                :src="postInfo.image"
                height="100px"
            />
        </div>

        <h2
            v-if="postInfo.title"
            class="title-post"
            v-text="!userData.userId ? postInfo.title : '---'"
        />

        <div
            v-if="postInfo.body"
            class="pb-4"
        >
            <span
                class="ql-editor pa-0"
                v-html="postInfo.body"
            />
        </div>

        <!-- admin user controls -->
        <div v-if="canManageEvaluations">
            <v-autocomplete
                v-model="userData.userId"
                :items="classifiers.users"
                :label="$t('evaluation.searchPerson')"
                item-text="username"
                item-value="id"
                clearable
                :filter="filterPerson"
            >
                <template v-slot:selection="{ item }">
                    <v-list-item-content>
                        <v-list-item-title v-text="item.name" />
                        <v-list-item-subtitle v-text="item.username" />
                    </v-list-item-content>
                </template>

                <template v-slot:item="{ item }">
                    <template>
                        <v-list-item-content>
                            <v-list-item-title v-text="item?.name" />
                            <v-list-item-subtitle v-text="item?.username" />
                        </v-list-item-content>
                    </template>
                </template>
            </v-autocomplete>
        </div>

        <div>
            <v-row justify="center">
                <v-expansion-panels
                    popout
                    multiple
                    flat
                    v-model="panels"
                >
                    <component
                        :is="componentHelpToLoad"
                        class="no-print"
                    ></component>

                    <v-expansion-panel>
                        <v-expansion-panel-header
                            class="pl-4"
                            :class="panels.includes(0) ? 'new-print-page' : 'no-print'"
                        >
                            <template v-slot:default="{ open }">
                                <span :class="open ? 'title' : ''">
                                    {{ $t('evaluation.stats.generalSummary') }}
                                </span>
                            </template>
                        </v-expansion-panel-header>

                        <v-expansion-panel-content>
                            <GeneralResume
                                :classifiers="classifiers"
                                :userData="userData"
                                :isDataLoaded="isDataLoaded"
                            />
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-header
                            class="pl-4"
                            :class="panels.includes(1) ? 'new-print-page' : 'no-print'"
                        >
                            <template v-slot:default="{ open }">
                                <span :class="open ? 'title' : ''">
                                    {{ $t('evaluation.stats.progression.menuTitle') }}
                                </span>
                            </template>
                        </v-expansion-panel-header>

                        <v-expansion-panel-content>
                            <Progression
                                :classifiers="classifiers"
                                :userData="userData"
                                :isDataLoaded="isDataLoaded"
                            />
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel
                        v-for="dimension in classifiers?.dimensions"
                        :key="dimension.id"
                    >
                        <v-expansion-panel-header
                            class="pl-4"
                            :class="panels.includes(dimension.id + 3) ? 'new-print-page' : 'no-print'"
                        >
                            <template v-slot:default="{ open }">
                                <span :class="open ? 'title' : ''">
                                    {{ dimension.name }}
                                </span>
                            </template>
                        </v-expansion-panel-header>

                        <v-expansion-panel-content>
                            <DimensionView
                                :dimension="dimension"
                                :classifiers="classifiers"
                                :userData="userData"
                                :isOpen="panels.includes(dimension.id + 3)"
                            />
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-header
                            class="pl-4"
                            :class="panels.includes(2) ? 'new-print-page' : 'no-print'"
                        >
                            <template v-slot:default="{ open }">
                                <span :class="open ? 'title' : ''">
                                    {{ $t('evaluation.stats.qualitativeQuestion') }}
                                </span>
                            </template>
                        </v-expansion-panel-header>

                        <v-expansion-panel-content>
                            <QualitativeQuestion
                                :classifiers="classifiers"
                                :userData="userData"
                                :isDataLoaded="isDataLoaded"
                                :isOpen="panels.includes(2)"
                            />
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-row>

            <div class="no-print d-flex justify-end mt-4">
                <c-btn
                    @click="print"
                    :outlined="false"
                    :text="false"
                    dark
                    minWidth="160"
                    large
                >
                    {{ $t('evaluation.stats.print') }}
                </c-btn>
            </div>
        </div>

        <div
            class="no-print d-flex justify-space-between mt-4"
            v-if="showScrollButton"
        >
            <c-btn
                @click="$router.push('/')"
                :outlined="false"
                :text="false"
                dark
                minWidth="160"
                large
            >
                {{ $t('posts.return') }}
            </c-btn>

            <c-btn
                @click="scrollToTop"
                :outlined="false"
                :text="false"
                dark
                minWidth="160"
                large
            >
                {{ $t('evaluation.stats.top') }}
            </c-btn>
        </div>

        <confirm-dialog ref="confirm" />
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import dateTimeMixin from '@/mixins/dateTimeMixin';
import scrollToTopMixin from '@/mixins/scrollToTopMixin';
import EvaluationsAPIService from '@/services/EvaluationsAPIService';

import GeneralResume from '@/components/games/evaluations/results/GeneralResume';
import Progression from '@/components/games/evaluations/results/Progression';
import DimensionView from '@/components/games/evaluations/results/DimensionView';
import QualitativeQuestion from '@/components/games/evaluations/results/QualitativeQuestion';

/* const EVALUATION_TYPES = ['selfAppraisal', 'responsible', 'collaborators', 'pairs'];

// evaluationTypes Ids
    selfAppraisal: 1,
    responsible: 2,
    collaborators: 3,
    pairs: 4
 */

export default {
    name: 'Results',
    mixins: [dateTimeMixin, scrollToTopMixin],
    components: {
        GeneralResume,
        Progression,
        DimensionView,
        QualitativeQuestion,
        alAL: () => import('@/components/games/evaluations/results/help-by-langs/al-AL'),
        brBR: () => import('@/components/games/evaluations/results/help-by-langs/br-BR'),
        csCH: () => import('@/components/games/evaluations/results/help-by-langs/cs-CH'),
        enCA: () => import('@/components/games/evaluations/results/help-by-langs/en-CA'),
        enUS: () => import('@/components/games/evaluations/results/help-by-langs/en-US'),
        esAR: () => import('@/components/games/evaluations/results/help-by-langs/es-AR'),
        esCL: () => import('@/components/games/evaluations/results/help-by-langs/es-CL'),
        esES: () => import('@/components/games/evaluations/results/help-by-langs/es-ES'),
        esMX: () => import('@/components/games/evaluations/results/help-by-langs/es-MX'),
        esPE: () => import('@/components/games/evaluations/results/help-by-langs/es-PE'),
        euES: () => import('@/components/games/evaluations/results/help-by-langs/eu-ES'),
        itIT: () => import('@/components/games/evaluations/results/help-by-langs/it-IT'),
        ltLT: () => import('@/components/games/evaluations/results/help-by-langs/lt-LT'),
        plPL: () => import('@/components/games/evaluations/results/help-by-langs/pl-PL'),
        ptPT: () => import('@/components/games/evaluations/results/help-by-langs/pt-PT'),
        roRU: () => import('@/components/games/evaluations/results/help-by-langs/ro-RU'),
        rsRS: () => import('@/components/games/evaluations/results/help-by-langs/rs-RS')
    },
    data: function () {
        return {
            userData: {
                userId: null,
                userStats: []
            },
            classifiers: {
                behaviors: [],
                attributes: [],
                dimensions: [],
                behaviorsMap: {},
                attributesMap: {},
                dimensionsMap: {},
                users: []
            },
            isDataLoaded: false,
            panels: []
        };
    },
    async created() {
        this.$store.dispatch('loading/start');

        this.isDataLoaded = false;

        await this.getClassifiers();

        await this.loadResults({});

        this.isDataLoaded = true;

        this.$store.dispatch('loading/end');
    },
    methods: {
        ...mapActions('statistics', ['loadUserEvaluations']),

        async getClassifiers() {
            try {
                const data = await EvaluationsAPIService.getClassifiers({
                    companyId: this.getCompanyId
                });

                const classifiers = data.data;

                classifiers.behaviorsMap = classifiers.behaviors.reduce((result, current) => {
                    result[current.id] = current;

                    return result;
                }, {});

                classifiers.dimensionsMap = classifiers.dimensions.reduce((result, current) => {
                    result[current.id] = current;

                    return result;
                }, {});

                classifiers.attributesMap = classifiers.attributes.reduce((result, current) => {
                    result[current.id] = current;

                    return result;
                }, {});

                this.classifiers = classifiers;
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'));
            }
        },

        async loadResults({ userId }) {
            try {
                if (!userId) {
                    userId = this.getUserId;
                }

                await this.loadUserEvaluations({
                    userId,
                    companyId: this.getCompanyId,
                    gameId: 51
                });
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'));
            }
        },

        print() {
            window.print();
        },

        filterPerson(item, queryText, itemText) {
            return (
                item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
                item.username.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
            );
        }
    },
    computed: {
        ...mapGetters(['getUser', 'getUserId']),
        ...mapGetters('permissions', ['canManageEvaluations']),
        ...mapGetters('languages', ['getLang']),
        ...mapGetters('cache', ['getPosts']),
        ...mapGetters(['getCompanyId']),

        componentHelpToLoad() {
            const component = this.getLang.replace('-', '');

            return component || 'esES';
        },

        postInfo() {
            const url = 'evaluation/results';
            let currentPost = this.getPosts.find(post => post.attributes.url === url);
            if (!currentPost) {
                currentPost = {
                    attributes: {
                        title2: '',
                        body: '',
                        image2: ''
                    }
                };
            }

            const { title2: title, body, image2 } = currentPost.attributes;
            const image = image2
                ? `${process.env.VUE_APP_API_ENDPOINT}media/imagesSimple?filename=posts/images/${image2}`
                : '';

            return { title, body, image };
        }
    },
    watch: {
        'userData.userId': {
            async handler(newUserId) {
                await this.loadResults({ userId: newUserId });
            }
        }
    }
};
</script>

<style lang="scss" scope>
.v-expansion-panel-content__wrap {
    padding: 0 !important;
}

.evaluating-results {
    &__tools {
        display: flex;
        justify-content: space-between;
        width: 100%;
        box-sizing: border-box;
        column-gap: 4rem;
        flex-wrap: wrap;

        & > div {
            max-width: 20rem;
        }
    }
}

.theme--light.v-text-field > .v-input__control > .v-input__slot::before {
    border-color: var(--v-primary-base, rgba(0, 0, 0, 0.42));
}
.v-text-field > .v-input__control > .v-input__slot::before {
    border-color: inherit;
    border-style: solid;
    border-width: thin 0 0 0;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
    padding: 0 6px !important;
}
</style>
