<template>
    <div class="px-4">
        <!-- <h6
            class="ml-4 mb-0"
            v-text="$t('evaluation.stats.progression.subtitle2')"
        /> -->

        <apexchart
            v-if="isDataLoaded"
            width="100%"
            height="450"
            :options="getChartOptions"
            :series="getChartSeries"
            class="mx-auto"
        />
    </div>
</template>

<script>
import evaluationResultMixin from '@/mixins/evaluationResultMixin';

export default {
    mixins: [evaluationResultMixin],
    props: {
        classifiers: {
            type: Object,
            required: true
        },
        isDataLoaded: {
            type: Boolean,
            required: true
        },
        getColumnSortedDataTableInfo: {
            type: Object,
            required: true
        }
    },
    computed: {
        getChartSeries() {
            const seriesLabelPrevious = [
                this.$t('evaluation.stats.progression.previousEvaluationType.selfAppraisal'),
                this.$t('evaluation.stats.progression.previousEvaluationType.responsible'),
                this.$t('evaluation.stats.progression.previousEvaluationType.pairs'),
                this.$t('evaluation.stats.progression.previousEvaluationType.collaborators')
            ];

            const seriesLabel = [
                this.$t('evaluation.stats.progression.currentEvaluationType.selfAppraisal'),
                this.$t('evaluation.stats.progression.currentEvaluationType.responsible'),
                this.$t('evaluation.stats.progression.currentEvaluationType.pairs'),
                this.$t('evaluation.stats.progression.currentEvaluationType.collaborators')
            ];

            const colors = ['#c00000', '#1f4e79', '#00b050', '#ffc000'];
            const colorsPrevious = colors.map(color => color + '55');

            const {
                attributeAvgMatrixWithCustomColumns: attributeAvgMatrixPrevious,
                columnsIndexToShow: columnsIndexToShowPrevious
            } = this.getColumnSortedDataTableInfo.previousPeriod;

            const { attributeAvgMatrixWithCustomColumns: attributeAvgMatrix, columnsIndexToShow } =
                this.getColumnSortedDataTableInfo.currentPeriod;

            const series = [];
            for (let index = 0; index < seriesLabel.length; index++) {
                // Previous period data.
                let data = attributeAvgMatrixPrevious.map(row => row[index]).slice(0, 6);
                let color = colorsPrevious[index];
                series.push({ name: seriesLabelPrevious[index], data, color });

                // Current period data.
                data = attributeAvgMatrix.map(row => row[index]).slice(0, 6);
                color = colors[index];
                series.push({ name: seriesLabel[index], data, color });
            }

            for (let index = seriesLabel.length - 1; index >= 0; index--) {
                if (!columnsIndexToShow[index] && !columnsIndexToShowPrevious[index]) {
                    series.splice(index * 2, 2);
                }
            }

            return series;
        },

        getChartOptions() {
            const MAX_LENGTH = this.$vuetify.breakpoint.mobile ? 15 : 30;

            return {
                chart: {
                    type: 'bar',
                    height: 350
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: this.classifiers?.attributes ?? [],
                    labels: {
                        show: true,
                        style: {
                            fontSize: '10px',
                            fontWeight: '600',
                            colors: Array(6).fill('#6b6a6a')
                        },
                        formatter: function (attribute) {
                            var words = attribute.name.split(' ');
                            let lines = [];
                            let currentLine = '';

                            for (let i = 0; i < words.length; i++) {
                                const word = words[i];
                                if (!currentLine.length || currentLine.length + word.length <= MAX_LENGTH) {
                                    currentLine += word + ' ';
                                } else {
                                    lines.push(currentLine.trim());
                                    currentLine = word + ' ';
                                }
                            }

                            if (currentLine.length > 0) {
                                lines.push(currentLine.trim());
                            }

                            return lines;
                        }
                    }
                },
                yaxis: {
                    title: {
                        text: ''
                    }
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val;
                        }
                    }
                }
            };
        }
    }
};
</script>

<style lang="scss"></style>
