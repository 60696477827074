<template>
    <div>
        <h6
            class="ml-4"
            v-text="$t('evaluation.stats.progression.subtitle1')"
        />

        <v-simple-table dense>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th></th>
                        <template v-for="(item, index) in getProgressionDataTable.columnsHeader">
                            <th
                                :key="index"
                                class="text-center"
                                v-if="getProgressionDataTable.columnToShow[index]"
                                v-html="item"
                            />
                        </template>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(dimension, index) in classifiers.dimensions">
                        <tr :key="dimension.id">
                            <td
                                colspan="100%"
                                v-text="dimension.name"
                                class="primary"
                            />
                        </tr>

                        <tr
                            v-for="(attribute, index) in classifiers.attributes.filter(
                                att => att.dimensions_id === dimension.id
                            )"
                            :key="`attribute${attribute.id}`"
                        >
                            <td>
                                <ul>
                                    <li v-text="attribute.name" />
                                </ul>
                            </td>
                            <template
                                v-for="(item, itemIndex) in getProgressionDataTable.dataCurrentPeriod[attribute.id - 1]"
                            >
                                <td
                                    v-if="getProgressionDataTable.columnToShow[itemIndex]"
                                    class="text-center"
                                    :class="getColorText(item, getDataPreviousValue(attribute.id - 1, itemIndex))"
                                >
                                    <span v-text="getDataPreviousValue(attribute.id - 1, itemIndex) || '-'" />
                                    /
                                    <span v-text="item || 0" />
                                </td>
                            </template>
                        </tr>
                    </template>

                    <tr>
                        <td></td>
                        <template
                            v-for="(item, itemIndex) in getProgressionDataTable.dataCurrentPeriod[
                                getProgressionDataTable.ROWS_COUNT - 1
                            ]"
                        >
                            <td
                                v-if="getProgressionDataTable.columnToShow[itemIndex]"
                                class="text-center"
                                :class="
                                    getColorText(
                                        item,
                                        getDataPreviousValue(getProgressionDataTable.ROWS_COUNT - 1, itemIndex)
                                    )
                                "
                            >
                                <span
                                    v-text="
                                        getDataPreviousValue(getProgressionDataTable.ROWS_COUNT - 1, itemIndex) || '-'
                                    "
                                />
                                /
                                <span v-text="item || 0" />
                            </td>
                        </template>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </div>
</template>

<script>
import evaluationResultMixin from '@/mixins/evaluationResultMixin';

export default {
    mixins: [evaluationResultMixin],
    props: {
        classifiers: {
            type: Object,
            required: true
        },
        getProgressionDataTable: {
            type: Object,
            required: true
        }
    },

    methods: {
        getColorText(value, previousValue) {
            previousValue = previousValue || 0;

            return value < previousValue ? 'text-red' : value > previousValue ? 'text-green' : '';
        },

        getDataPreviousValue(rowIndex, columnIndex) {
            return this.getProgressionDataTable.dataPreviousPeriod?.[rowIndex]?.[columnIndex];
        }
    }
};
</script>

<style lang="scss" scoped></style>
